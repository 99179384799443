export const environment = {
  name: 'acceptance',
  production: true,
  apiBaseUrl: 'https://test-api.misterklic.nl',
  frontendUrl: 'https://test.misterklic.nl',
  vectorTiles: 'https://test-tiles.misterklic.nl',
  googleAnalytics: {
    tag: 'G-HHXH1S6KF3',
    enable: false
  },
  leafinfo: {
    tag: 'LI-64784FCC1A0ED',
    enable: true
  },
  storyBlokAccessToken: 'tob6FRlDSEwj0nwbrj2ghwtt',
  storyBlokCache: 'none',
  storyBlokVersion: 'draft',
  rollbar: {
    enable: true,
    token: 'f40ddd4905a9472dbdab1599cb5ec4c0'
  },
  streetsmartApiKey: 'aLUxqjsN2_U9On4hyrUpuPQRyTExavM5qPYVW_sZibQliXYlejgdqH4dyXQ5ivAx'
};